<template>
  <div class="meter-device-search pa-10">

    <title-page icon="mdi-counter">
      {{ $t('meter-device.search') }}
    </title-page>

    <meter-device-list with-control />

  </div>
</template>

<script>


import MeterDeviceList from "@/components/elements/MeterDeviceList.vue";

export default {
  components: {
    MeterDeviceList
  },
}
</script>

<style lang="scss">
.meter-device-search {

}
</style>
