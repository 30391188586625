<template>
  <v-row no-gutters class="mt-10">
    <v-col>
      <v-data-table
          class="cp-list"
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
      >
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>


import SessionAuthorizationRepository from "@repository/SessionAuthorizationRepository";


export default {
  components: {

  },
  data() {
    return {
      loading: false,
      options: null,
      items: [],
    }
  },
  props: {
    token: Object,
  },
  mounted() {
    if(!this.currentUser.permissions.includes('OPERATOR_READ')) {
      this.$router.replace({name: 'dashboard'})
    }

    this.loadData()
  },

  computed: {
    headers() {
      return [
        {text: this.$t('authorization-id'), value: 'id'},
        {text: this.$t('authorization-method'), value: 'method'},
        {text: this.$t('created-at'), value: 'createdAt'},
        {text: this.$t('charging-point-id'), value: 'evse.chargingPointId'},
        {text: this.$t('charging-point-identifier'), value: 'evse.chargingPointIdentifier'},
        {text: this.$t('evse-id'), value: 'evse.evseId'},
        {text: this.$t('network'), value: 'evse.network'},
        {text: this.$t('payment-method-id'), value: 'paymentmethodId'},
        {text: this.$t('session-authorization.reject-reason'), value: 'rejectReason'},
        {text: this.$t('session-id'), value: 'sessionId'},
        {text: this.$t('status'), value: 'status'},
        {text: this.$t('token.id'), value: 'token.id'},
        {text: this.$t('customer-id'), value: 'token.customerId'},
        {text: this.$t('evco-id'), value: 'token.evcoId'},
        {text: this.$t('token-uid'), value: 'token.uid'},
        {text: this.$t('transaction-id'), value: 'transactionId'},
        {text: this.$t('authorization-type'), value: 'type'},
      ]
    },
  },

  methods: {

    loadData() {

      this.loading = true
      let options = this.options
      let query = ""
      let tokenId = this.token?.id
      let offset = options.itemsPerPage * options.page - options.itemsPerPage;
      let limit = options.itemsPerPage;
      let sortBy = options.sortBy.length > 0 ? options.sortBy[0] : 'createdAt'
      let sortOrder = options.sortDesc.length > 0 && options.sortDesc[0] ? 'desc' : 'asc'
      SessionAuthorizationRepository.search({
        query,
        offset,
        limit,
        sortBy,
        sortOrder,
        tokenId,
      }).then(result => {
        this.items = result.items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.loading = false
      })
    },


  }
}
</script>