<template>
  <div>
    <!-- === MODALS === -->

    <!-- Edition Modal -->
    <v-dialog v-model="editDialog.displayed" max-width="400">
      <v-card v-if="meterDevice">
        <v-card-title>{{ editDialog.readingId ? $t('edit') : $t('create') }}</v-card-title>
        <v-card-text>
          <!-- Compteur -->
          <v-select
              v-model="editDialog.meterDevice"
              :label="$tc('meter-device.meter-device', 2)"
              :items="meterDevices"
              item-text="name"
              item-value="id"
          ></v-select>

          <!-- Valeur -->
          <v-text-field
              v-model.number="editDialog.readingValue"
              :hint="$t('meter-device.reading.comma-needed-hint')"
              :label="$t('value') + ' (' + $t('usages.kwh') + ')'"
          ></v-text-field>

          <!-- Date -->
          <date-picker
              v-model="editDialog.readingDate"
              :label="$t('date')"
          ></date-picker>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn v-if="!editDialog.readingId" text @click="createReading" color="primary" :disabled="isSubmitting || !canSaveModal">{{ $t('add') }}</v-btn>
          <v-btn v-else text @click="updateReading" color="primary" :disabled="isSubmitting || !canSaveModal">{{ $t('save') }}</v-btn>
          <v-btn text @click="editDialog.displayed = false">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Picture Modal -->
    <v-dialog v-model="showPictureDialog.displayed" max-width="800px">
      <v-card>
        <v-progress-linear indeterminate v-if="showPictureDialog.loading"></v-progress-linear>
        <v-card-title>{{ $t('photo') }}</v-card-title>
        <v-card-text>
          <v-img :src="showPictureDialog.picture" :key="showPictureDialog.id" width="100%"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showPictureDialog.displayed = false">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row v-if="customer != null">
      <v-col v-if="canBeInvitedToInputMeterDeviceReadings()">
        <!-- Invitation button -->
        <v-btn class="ml-3 mb-6" @click="requestMeterDeviceReadings()" small>
          <v-icon left color="primary">mdi-email-plus-outline</v-icon>
          {{ $t('meter-device.reading.send-invitation-named', { name: customerName }) }}
        </v-btn>

        <!-- Ajouter un relevé -->
        <v-btn class="ml-3 mb-6" @click="openEditReadingDialog()" small>
          <v-icon left color="primary">mdi-plus</v-icon>
          {{ $t('meter-device.reading.add') }}
        </v-btn>
      </v-col>

      <v-col>
        <!-- Choix du statut -->
        <v-select
            v-model="readingStatus"
            :label="$t('status')"
            :items="[
              {text: $t('meter-device.reading.status.PENDING'), value: 'PENDING'},
              {text: $t('meter-device.reading.status.ACCEPTED'), value: 'ACCEPTED'},
              {text: $t('meter-device.reading.status.REJECTED'), value: 'REJECTED'},
          ]"
        ></v-select>
      </v-col>
    </v-row>

    <!-- === TABLE === -->

    <v-data-table
        :headers="headers"
        :items="readings"
        :items-per-page="10"
        :loading="loading"
        :item-class="tableRowClasses"
    >
      <template v-slot:item.pictureUrl="{ item, value }">
        <v-btn class="btn-show-picture" text color="primary" v-if="value && value !== ''" @click="showPicture(item)">{{ $t('show-picture') }}</v-btn>
      </template>

      <template v-slot:item.readingValue="{ value }">
        <v-icon left v-if="value === 0">mdi-alert</v-icon>
        {{ value / 1000 }} {{ $t('usages.kwh') }}
      </template>

      <template #item.customerName="{ item }">
        <div v-if="item.customer">{{ item.customer.name }}</div>
        <div v-else><v-icon color="error" left>mdi-alert</v-icon></div>
      </template>

      <template #item.readingDate="{ value, item }">
        <v-icon left v-if="item.duplicated || !isDateValid(item)">mdi-alert</v-icon>
        {{ new Date(value).toLocaleDateString() }}
      </template>

      <template #item.lastReading="{ item }">
        <div v-if="getLastReadingValue(item.meterDevice).value">
          {{ (getLastReadingValue(item.meterDevice).value / 1000) + " " + $t('usages.kwh') }} - {{ getLastReadingValue(item.meterDevice).date|formatDate('DD/MM/YYYY') }}
        </div>
        <div v-else>
          {{ $t('meter-device.reading.no-initial-value') }}
        </div>
      </template>

      <template #item.consumedEnergy="{ item }">
        <div v-if="isOlderReading(item)">
          <div v-if="energyConsumed(item)">
            {{ (energyConsumed(item) / 1000) + ' ' + $t('usages.kwh') }}
          </div>
          <div v-else>
            <v-icon color="error" left>mdi-alert</v-icon>
            {{ item.missingMeterMaxValue ? $t("meter-device.missing-max-value") : '' }}
          </div>
        </div>
      </template>

      <template #item.averageMonthlyEnergyConsumed="{ item }">
        <div v-if="isOlderReading(item)">
          <v-icon v-if="calculateAverageMonthlyEnergyConsumed(item) >= 40000" left>mdi-alert</v-icon>
          <div v-if="calculateAverageMonthlyEnergyConsumed(item) !== '0'">{{ (calculateAverageMonthlyEnergyConsumed(item) / 1000) + ' ' + $t('usages.kwh')}}</div>
        </div>
      </template>

      <template #item.actions="{ item }">
        <div class="d-flex justify-end" v-if="item.status === 'PENDING'">
          <!-- Accept -->
          <v-btn icon @click="acceptReading(item)" :title="$t('validate')" v-if="item.customer && isOlderReading(item) && energyConsumed(item) >= 0 && isDateValid(item)">
            <v-icon small color="green">mdi-check</v-icon>
          </v-btn>

          <!-- Reject -->
          <v-btn icon @click="rejectReading(item)">
            <v-icon small color="red" :title="$t('reject')">mdi-cancel</v-icon>
          </v-btn>

          <!-- Edit -->
          <v-btn icon @click="openEditReadingDialog(item)" :title="$t('edit')">
            <v-icon small color="blue">mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import MeterDeviceRepository from "@repository/MeterDeviceRepository";
import CustomerRepository from "@repository/CustomerRepository";

export default {
  data() {
    return {
      showPictureDialog: {
        displayed: false,
        picture: null,
        loading: false,
      },
      editDialog: {
        displayed: false,
        readingId: null,
        readingValue: null,
        readingDate: null,
        meterDevice: null,
      },
      duplicatesCount: 0,
      isSubmitting: false,
      readings: [],
      meterDevices: [],
      organization: null,
      meterDevice: null,
      loading: false,
      readingStatus: "PENDING",
    }
  },

  computed: {
    headers() {
      return [
        { text: this.$tc('meter-device.meter-device', 2), value: 'meterDevice.name' },
        { text: this.$tc('customer.customer', 1), value: 'customerName' },
        { text: this.$t('meter-device.reading.last'), value: 'lastReading' },
        { text: this.$t('new-value'), value: 'readingValue' },
        { text: this.$t('charging-point.consumed-energy'), value: 'consumedEnergy' },
        // { text: this.$t('charging-point.average-consumed-energy-monthly'), value: 'averageMonthlyEnergyConsumed'},
        { text: this.$t('date'), value: 'readingDate' },
        { text: this.$t('photo'), value: 'pictureUrl' },
        { text: "", value: 'actions', width: '150'},
      ]
    },

    canSaveModal() {
      return this.editDialog.meterDevice && this.editDialog.readingValue && this.editDialog.readingDate
    },

    tableRowClasses() {
      const duplicates = this.findDuplicates();
      return (item) => {
        return item.duplicated ? 'duplicate' : '';
      };
    },

    customer() {
      return this.meterDevice?.customer?.id;
    },

    customerName() {
      if(!this.customer) {
        return ""
      }
      const { firstname, lastname } = this.meterDevice.customer;
      return `${firstname || ""} ${lastname || ""}`.trim() || "";
    },
  },

  watch: {
    'showPictureDialog.displayed'(val) {
      if (!val) {
        this.showPictureDialog.picture = null
      }
    },

    readingStatus() {
      this.loadData()
    }
  },

  mounted() {
    this.meterDevice = this.$parent.meterDevice
    this.organization = this.$parent.organization

    this.loadData()
    this.getAllMeterDevices()
  },

  methods: {
    loadData() {
      this.loading = true;

      MeterDeviceRepository.searchMeterDeviceReadings(this.meterDevice, this.organization, this.readingStatus)
          .then(readings => {
            this.readings = readings

            readings.forEach(reading => {
              if (!reading.meterDevice.maxMeterValue) {
                reading.missingMeterMaxValue = true;
              }
            });

            this.loading = false;

            this.duplicatesCount = this.findDuplicates().length
          }).catch(err => {
        this.loading = false;
        console.log(err)
        this.notifyError(err)
      })
    },

    getAllMeterDevices() {
      this.meterDevices = []

      if(this.meterDevice) {
        this.meterDevices = [this.$parent.meterDevice]
        this.editDialog.meterDevice = this.meterDevices[0].id // on le sélectionne par defaut
      } else if(this.organization) {
        //TODO: a tester quand on fera la page des readings sur une société
        const data = {
          organizationId: this.organizationId
        }

        MeterDeviceRepository.search(data).then(meterDevices => {
          this.meterDevices = meterDevices
        }).catch(err => {
          console.log(err)
          this.notifyError(err)
        })
      }
    },

    getLastReadingValue(meterDevice) {
      let value = meterDevice.lastMeterValue;
      let date = meterDevice.lastMeterDate;

      if(!value || !date) {
        value = meterDevice.initialMeterValue ?? 0;
        date = meterDevice.createdAt;
      }

      return {date, value};
    },

    isDateValid(reading) {
      if(!reading.meterDevice.lastMeterDate) {
        return true
      }
      return new Date(reading.readingDate) > new Date(reading.meterDevice.lastMeterDate);
    },

    calculateAverageMonthlyEnergyConsumed(reading) {
      let lastReading = this.getLastReadingValue(reading.meterDevice);

      if(!lastReading) {
        return "";
      }

      let energyConsumed = this.energyConsumed(reading)

      let currentDate = new Date(reading.readingDate);
      let lastDate = new Date(lastReading.date);

      currentDate = this.normalizeDateToMidnight(currentDate);
      lastDate = this.normalizeDateToMidnight(lastDate);

      // Check if the dates are valid
      if (isNaN(currentDate) || isNaN(lastDate)) {
        throw new Error("Invalid date format");
      }

      // Calculate the total number of days between the two readings
      let daysDifference = Math.floor((currentDate - lastDate) / (1000 * 60 * 60 * 24));

      if(daysDifference === 0) {
        daysDifference = 1;
      }

      // Calculate the average daily consumption
      let averageDailyConsumption = energyConsumed / daysDifference;

      // Estimate the average monthly consumption (assuming 30 days in a month)
      let averageMonthlyConsumption = averageDailyConsumption * 30;

      return parseFloat(averageMonthlyConsumption.toFixed(2)).toString();
    },

    normalizeDateToMidnight(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },

    findDuplicates() {
      const duplicates = [];

      // Double boucle pour comparer chaque élément avec tous les autres
      for (let i = 0; i < this.readings.length; i++) {
        for (let j = i + 1; j < this.readings.length; j++) {
          const item1 = this.readings[i];
          const item2 = this.readings[j];

          // Comparaison des clés (id du dispositif de mesure + date de lecture)
          const key1 = item1.meterDevice.id + item1.readingDate;
          const key2 = item2.meterDevice.id + item2.readingDate;

          // Si les clés sont identiques, cela signifie qu'il y a un doublon
          if (key1 === key2) {
            // Marquer les deux éléments comme doublons
            item1.duplicated = true;
            item2.duplicated = true;

            // Ajouter les deux éléments à la liste des doublons (si ce n'est pas déjà fait)
            if (!duplicates.includes(item1)) {
              duplicates.push(item1);
            }
            if (!duplicates.includes(item2)) {
              duplicates.push(item2);
            }
          }
        }
      }

      return duplicates;
    },

    energyConsumed(item) {
      let lastMeterValue = item.meterDevice.lastMeterValue ?? 0;

      if (lastMeterValue < item.readingValue) {
        return item.readingValue - lastMeterValue;
      } else if (lastMeterValue > item.readingValue) {
        if(this.meterDevice.maxMeterValue) {
          return this.meterDevice.maxMeterValue - lastMeterValue + item.readingValue;
        }
      } else {
        return null
      }
    },

    showPicture(reading) {
      this.showPictureDialog.id = null
      this.showPictureDialog.displayed = true
      this.showPictureDialog.loading = true

      MeterDeviceRepository.getMeterDeviceReadingPicture(reading.id)
          .then(result => {
            this.showPictureDialog.id = reading.id
            this.showPictureDialog.picture = result
            this.showPictureDialog.loading = false
          }).catch(err => {
        this.showPictureDialog.loading = false
        console.log(err)
        this.notifyError(err)
      })
    },

    acceptReading(reading) {
      MeterDeviceRepository.acceptReadings([reading.id]).then(response => {
        this.loadData()
        this.showLoading(false)
        this.notifySuccess(this.$t('update-success'))
      }).catch(err => {
        this.showLoading(false)
        this.notifyError(err)
      })
    },

    rejectReading(reading) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)

          MeterDeviceRepository.rejectReadings([reading.id]).then(response => {
            this.loadData()
            this.showLoading(false)
            this.notifySuccess(this.$t('update-success'))
          }).catch(err => {
            this.showLoading(false)
            this.notifyError(err)
          })
        }
      })
    },

    openEditReadingDialog(reading) {
      this.editDialog.displayed = true

      if(reading) {
        this.editDialog.readingId = reading.id
        this.editDialog.meterdevice = reading.meterDevice
        this.editDialog.readingValue = reading.readingValue / 1000
        this.editDialog.readingDate = this.formatDate(reading.readingDate, 'YYYY-MM-DD')
      } else { // Ajout d'une nouvelle ligne
        this.editDialog.readingId = null
        this.editDialog.readingValue = null
        this.editDialog.readingDate = this.formatDate(new Date(), 'YYYY-MM-DD')
      }
    },

    updateReading() {
      this.isSubmitting = true

      MeterDeviceRepository.updateReading(this.editDialog.readingId, this.editDialog.meterDevice, this.editDialog.readingValue, this.editDialog.readingDate).then(response => {
        this.loadData()
        this.notifySuccess(this.$t('update-success'))
        this.isSubmitting = false
        this.editDialog.displayed = false
        this.editDialog.readingValue = null
        this.editDialog.readingDate = null
      }).catch(err => {
        this.notifyError(err)
        this.isSubmitting = false
      })
    },

    createReading() {
      this.isSubmitting = true

      MeterDeviceRepository.createReading(this.editDialog.readingId, this.editDialog.meterDevice, this.editDialog.readingValue, this.editDialog.readingDate).then(response => {
        this.loadData()
        this.notifySuccess(this.$t('create-success'))
        this.isSubmitting = false
        this.editDialog.displayed = false
        this.editDialog.readingValue = null
        this.editDialog.readingDate = null
      }).catch(err => {
        this.notifyError(err)
        this.isSubmitting = false
      })
    },

    canBeInvitedToInputMeterDeviceReadings() {
      return !(!this.meterDevice || this.meterDevice.type !== 'mid-meter' || this.meterDevice.customer === null);
    },

    requestMeterDeviceReadings() {
      this.showLoading(true)
      CustomerRepository.requestMeterDeviceReadings(this.meterDevice.customer.id).then(() => {
        this.showLoading(false)
        this.notifySuccess(this.$t('invitation.sent'))
      }).catch(err => {
        this.showLoading(false)
        this.notifyError(err.response?.data?.message || err.message)
      })
    },

    isOlderReading(reading) {
      if (!reading || !reading.meterDevice || !reading.readingDate) {
        return false;
      }

      const meterDeviceReadings = this.readings.filter(item => item.meterDevice && item.meterDevice.id === reading.meterDevice.id);

      if (meterDeviceReadings.length === 0) {
        return false; // Pas d'autres lectures pour ce meterDevice
      }

      const readingDate = new Date(reading.readingDate);

      // Vérifier si la lecture actuelle est la plus ancienne
      return meterDeviceReadings.every(item => {
        const otherReadingDate = new Date(item.readingDate);
        return readingDate <= otherReadingDate;
      });
    }
  }
}
</script>

<style lang="scss">
.btn-show-picture {
  font-size: 1rem;
}

.duplicate {
  background: rgba(255, 165, 0, 0.5);
}
</style>