import Vue from 'vue'

class SessionRepository {

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/session-authorizations/search',
        method: 'post',
        data
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


}


export default new SessionRepository()