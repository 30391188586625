<template>
  <div class="pa-10 pt-0" v-if="token">


    <tab-navigation
        :title="$tc('rfid.rfid')"
        icon="mdi-counter"
        :breadcrumbs="breadcrumbs"
    >
      <v-tabs>
        <v-tab :to="{name: 'tokens.detail'}" exact>{{ $t('information') }}</v-tab>
        <v-tab :to="{name: 'tokens.usages'}" exact>{{ $t('customer.usages') }}</v-tab>
        <v-tab :to="{name: 'tokens.authorizations'}" exact>{{ $t('session-authorization.logs') }}</v-tab>
      </v-tabs>
    </tab-navigation>




    <router-view></router-view>

  </div>
</template>

<script>
import TokenRepository from "@repository/TokenRepository";
import TabNavigation from "@blocks/TabNavigation.vue";

export default {
  name: 'TokenDetail',
  components: {TabNavigation},
  data() {
    return {
      token: null,
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {
    id() {
      this.loadData()
    }
  },

  computed: {

    id() {
      return this.$route.params.id
    },

    breadcrumbs() {
      let breadcrumbs = [{text: this.$tc('rfid.rfid'), to: {name: 'token.search'}, exact: true}]
      if(this.token) {
        breadcrumbs.push({text: this.token.uid})
      }

      return breadcrumbs
    }
  },

  methods: {
    loadData() {
      if(this.id) {
        this.showLoading(true)
        TokenRepository.get(this.id).then(token => {
          this.token = token
          this.showLoading(false)
        }).catch(err => {
          this.$dialog.notify.error(err.response.data?.message || err.message)
          this.showLoading(false)
        })
      }
    },
  },

}
</script>

<style lang="scss">


</style>
