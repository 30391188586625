<template>
  <div class="charging-point-type-edit pa-10">


    <title-page icon="ico-status-charging" class="mb-0">
      {{ $t("rfid.edit") }}
    </title-page>

    <div class="mt-5">
      <headline>
        {{ $t('rfid.rfid') }}
      </headline>
      <v-form class="form-grid" ref="form" @submit.prevent="save">
        <v-row justify="center">
          <v-col>
            <form-field
                :label=" $t('rfid.rfid-card-no')"
                v-model="form.uid"
                :rules="[commonRules.required]"
                :readonly=true
            />
          </v-col>
          <v-col>
            <form-field
                :label=" $t('rfid.rfid-card-name')"
                v-model="form.name"
            />
          </v-col>

        </v-row>
        <v-row justify="center">
          <v-col>
            <form-field
                :label="$t('rfid.rfid-card-physical-reference')"
                v-model="form.physicalReference"
                :rules="[commonRules.required]"
            />
          </v-col>
          <v-col>
          </v-col>

        </v-row>

        <v-row>
          <v-col>
            <form-field
                :label=" $t('rfid.rfid-card-enabled')"
                v-model="form.enabled"
                type="switch"
            />
          </v-col>
          <v-col>
            <form-field
                :label=" $t('rfid.rfid-corporate')"
                v-model="form.corporate"
                type="switch"
            />
          </v-col>
          <v-col v-if="currentUser.isZE">
            <form-field
                :label=" $t('rfid.rfid-added-by-customer')"
                v-model="form.private"
                type="switch"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <form-field
                type="customer-select"
                :label="$tc('customer.customer',1)"
                v-model="form.customerId"
                selection-with-no-extra
                col-field="9"
                clearable
            />
          </v-col>
          <v-col>
            <form-field
                type="organization-select"
                :label="$tc('organization.organization', 1)"
                v-model="form.organizationId"
                selection-with-no-extra
                col-field="9"
                clearable
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn class="mr-5" @click="cancel">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="primary" type="submit">
              {{ $t('save') }}
            </v-btn>
          </v-col>

        </v-row>
      </v-form>
    </div>

  </div>
</template>

<script>


import RulesMixin from "@mixins/RulesMixin";
import TokenRepository from "@repository/TokenRepository";
import CustomerSelect from "@blocks/Select/CustomerSelect.vue";
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";

export default {
  components: {},


  mixins: [RulesMixin],
  data: () => ({
    form: {
      customerId: null,
      organizationId: null,
    },
  }),
  watch: {

    form: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true
    }
  },
  mounted() {
    if (!this.currentUser.permissions.includes('OPERATOR_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    this.form = this.cloneObj(this.token)
    this.form.customerId = this.token?.customer?.id ?? null
    this.form.organizationId = this.token?.organization?.id ?? null
  },
  computed: {
    token() {
      return this.$parent.token ?? null
    },
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        const form = this.cloneObj(this.form)
        delete form.bigendianValidated
        delete form.createdAt
        delete form.paymentAttachedToOrganization
        delete form.whitelist
        this.showLoading(true)
        TokenRepository.update(form).then(data => {
          this.$parent.loadData()
          this.cleanUnsavedChanges()
          this.$router.replace({name: 'tokens.detail'})
          this.$dialog.notify.success(this.$t('update-success'))
          this.showLoading(false)
        }).catch(err => {
          this.$dialog.notify.error(err.response.data.message || err.message)
          this.showLoading(false)
        })
      }
      //
    },
    cancel() {
      this.$router.replace({name: 'tokens'})
    },
  },
}
</script>

<style lang="scss">

</style>
