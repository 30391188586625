<template>
  <div class="organization-view  pa-10 pt-0" v-if="meterDevice">


    <tab-navigation
        :title="$tc('meter-device.meter-device')"
        icon="mdi-counter"
        :breadcrumbs="breadcrumbs"
    >
      <v-tabs>
        <v-tab :to="{name: 'meter-device.detail'}" exact>{{ $t('information') }}</v-tab>
        <v-tab :to="{name: 'meter-device.usages'}">{{ $t('charging-point.usage') }}</v-tab>
        <v-tab v-if="meterDevice.type === 'mid-meter'" :to="{name: 'meter-device.readings'}">{{ $tc('meter-device.reading.meter', 2) }}</v-tab>
      </v-tabs>
    </tab-navigation>




    <router-view></router-view>

  </div>
</template>

<script>
import MeterDeviceRepository from "@/repository/MeterDeviceRepository"
import TabNavigation from "@blocks/TabNavigation.vue";

export default {
  name: 'MeterDeviceDetail',
  components: {TabNavigation},
  data() {
    return {
      meterDevice: null,
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {
    id() {
      this.loadData()
    }
  },

  computed: {

    id() {
      return this.$route.params.id
    },

    breadcrumbs() {
      let breadcrumbs =  [
        {text: this.$tc('meter-device.meter-device'), to: {name: 'meter-device'}, exact: true},
      ]

      if(this.meterDevice) {
        if(this.meterDevice.organization) {
          breadcrumbs.push({text: this.meterDevice.organization?.name ?? '-', to: {name: 'organizations.charging-points', params: {id: this.meterDevice.organization?.id}}, exact: true})
        }
        breadcrumbs.push({text: this.meterDevice.name})
      }

      return breadcrumbs
    }
  },

  methods: {
    loadData() {
      return new Promise((resolve, reject) => {
        if(this.id) {
          this.showLoading(true)
          MeterDeviceRepository.get(this.id).then(meterDevice => {
            if(!meterDevice.customer) {
              meterDevice.customer =  {}
            }
            this.meterDevice = meterDevice
            this.showLoading(false)
            resolve(meterDevice)
          }).catch(err => {
            this.$dialog.notify.error(err.response.data?.message || err.message)
            this.showLoading(false)
            reject(err)
          })
        }
      })
    },
  },

}
</script>

<style lang="scss">


</style>
