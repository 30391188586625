<template>
  <div class="meter-device-edit">

    <div>
      <v-row>
        <v-col cols="12">
          <v-form ref="form" @submit.prevent="save">

            <headline>
              {{ $tc('meter-device.meter-device') }}
            </headline>
            <v-row>
              <v-col cols="12">
                <form-field
                    type="select"
                    :label="$t('meter-device.type')"
                    v-model="form.type"
                    :items="types"
                    :rules="[
                      commonRules.required
                    ]"
                    col-field="9"
                    disabled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <form-field
                    type="select"
                    :label="$t('meter-device.statement-mode')"
                    v-model="form.statementMode"
                    :items="statementModes"
                    :rules="[
                      commonRules.required
                    ]"
                    disabled
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-field
                    type="select"
                    :label="$t('meter-device.statement-type')"
                    v-model="form.statementType"
                    :items="statementTypes"
                    :rules="[
                      commonRules.required
                    ]"
                    disabled
                />
              </v-col>
            </v-row>


            <headline class="mt-10">
              {{ $tc("general-information") }}
            </headline>
            <v-row>
              <v-col cols="12" md="6">
                <form-field
                    :label="$t('name')"
                    v-model="form.name"
                    :rules="[
                        commonRules.required,
                    ]"
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-field
                    :label="$t('external-reference')"
                    v-model="form.externalId"
                    :rules="[

                    ]"
                    :readonly="form.statementMode === 'API'"
                />
              </v-col>

            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <form-field
                    :label="$t('meter-device.brand')"
                    v-model="form.brand"
                    :rules="[

                    ]"
                />
              </v-col>

              <v-col cols="12" md="6">
                <form-field
                    :label="$t('meter-device.model')"
                    v-model="form.model"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" v-if="form.meterIdFromApi">
                <form-field
                    type="select"
                    :label="$t('meter-device.meter')"
                    v-model="form.meterIdFromApi"
                    :items="meters"
                    readonly
                />
              </v-col>
            </v-row>


            <headline class="mt-10">
              {{ $tc("customer.customer") }}
            </headline>
            <v-row>
              <v-col cols="12">
                <form-field
                    type="customer-select"
                    :label="$tc('customer.customer',1)"
                    v-model="form.customer.id"
                    selection-with-no-extra
                    col-field="9"
                />
              </v-col>
            </v-row>

            <headline class="mt-10">
              {{ $tc("location.location") }}
            </headline>
            <v-row>
              <v-col cols="12" md="6">
                <form-field
                    :label="$t('name')"
                    :value="form.location?.name"
                    disabled
                />
              </v-col>
              <v-col cols="12" md="6" class="text-center">
                <form-field
                    :label="$t('organization.organization')"
                    :value="form.organization?.name"
                    disabled
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <form-field
                    :label="$t('street')"
                    :value="form.location?.address?.street"
                    disabled
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-field
                    :label="$t('state')"
                    :value="form.location?.address?.state"
                    disabled
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-field
                    :label="$t('city')"
                    :value="form.location?.address?.city"
                    disabled
                />
              </v-col>
              <v-col cols="12" md="6">
                <form-field
                    :label="$t('zipcode')"
                    :value="form.location?.address?.zipcode"
                    disabled
                />
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                    @click="$refs.locationModal.open()"
                    small
                >
                  {{ $t('charging-point.edit-location') }}
                </v-btn>

              </v-col>
            </v-row>

            <headline class="mt-10">
              {{ $t("settings") }}
            </headline>
            <v-row>
              <v-col cols="12" md="6">
                <form-field
                    input-type="number"
                    min="0"
                    :label="$t('meter-device.initial-meter-value')"
                    v-model.number="form.initialMeterValue"
                    :step="1"
                    @input="filterInput"
                    :rules="[

                    ]"
                >
                  <template v-slot:append>
                    /{{ $t('usages.kwh') }}
                  </template>
                </form-field>
              </v-col>
              <v-col cols="12" md="6">
                <form-field
                    input-type="number"
                    min="0"
                    :label="$t('meter-device.max-meter')"
                    v-model.number="form.maxMeterValue"
                    :rules="[

                    ]"
                >
                  <template v-slot:append>
                    /{{ $t('usages.kwh') }}
                  </template>
                </form-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <form-field
                    :label="$t('meter-device.last-meter-value')"
                    v-model.number="form.lastMeterValue"
                    :rules="[]"
                   readonly
                >
                  <template v-slot:append>
                    /{{ $t('usages.kwh') }}
                  </template>
                </form-field>
              </v-col>
              <v-col cols="12" md="6">
                <form-field
                    :label="$t('meter-device.last-meter-date')"
                    v-model="form.lastMeterDate"
                    type="date"
                ></form-field>
              </v-col>

            </v-row>
            <v-row >

              <v-col cols="12" md="6">
                <v-row class="form-field">
                  <v-col sm="6" class="label">
                <span>
                  {{ $t('charging-point.energy-provider-kwh-price') }}
                </span>
                  </v-col>
                  <v-col class="input-wrapper">
                    <meter-device-energy-price-module
                        :meter-device-id="meterDevice.id"
                    />
                  </v-col>
                </v-row>


              </v-col>
            </v-row>

            <v-row class="mt-10">
              <v-col cols="12" class="text-center">
                <v-btn type="submit" color="primary">
                  {{ $t('save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </div>

    <location-modal ref="locationModal" :location.sync="location" />

  </div>
</template>

<script>

import RulesMixin from "@mixins/RulesMixin"
import MeterDeviceRepository from "@repository/MeterDeviceRepository"
import LocationModal from "@pages/ChargingPoint/Detail/modals/LocationModal"
import MeterDeviceEnergyPriceModule from "@pages/MeterDevice/Detail/modals/MeterDeviceEnergyPriceModule.vue"

export default {
  components: {
    MeterDeviceEnergyPriceModule,
    LocationModal
  },
  data() {
    return {
      form: {
        location: null,
        organization: null,
        customer: {},
        meterIdFromApi: null,
        meterNameFromApi: null,
      },
      types:[],
      meters: [],
      OrganizationName: null,
    }
  },

  mixins: [
    RulesMixin,
  ],


  mounted() {
    if (!this.currentUser.permissions.includes('OPERATOR_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    this.loadTypes()

    this.form = this.cloneObj(this.meterDevice)

    this.form.initialMeterValue = this.form.initialMeterValue / 1000
    this.form.lastMeterValue = this.form.lastMeterValue / 1000
    this.form.maxMeterValue = this.form.maxMeterValue / 1000

    this.$nextTick(() => {
      this.cleanUnsavedChanges()
    })
  },

  watch: {

    form: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true
    },

    'form.statementMode': {
      handler(newMode, oldMode) {
        if (newMode === 'API') {
          this.meters = []
          MeterDeviceRepository.getdomoconsometers().then(data => {
            this.meters = data.data.map(d => ({text: d.name, value: d._id}))
          }).catch(err => {
            this.$dialog.notify.error(err.response.data?.message || err.message)
          })
        }
      },
      deep: true
    },
    'form.meterIdFromApi'(newMode) {
      if (newMode) {
        const meter = this.meters.find(m => m.value === newMode)
        this.form.meterNameFromApi = meter?.text
      }
    }
  },

  computed: {
    id() {
      return this.$route.params.id ?? null
    },

    meterDevice() {
      return this.$parent.meterDevice
    },

    statementModes() {
      return [
        {text: this.$t('enums.statement-mode.IMPORT'), value: 'IMPORT'},
        {text: this.$t('enums.statement-mode.API'), value: 'API'},
        {text: this.$t('enums.statement-mode.MANUAL'), value: 'MANUAL'},
      ]
    },

    statementTypes() {
      return [
        {text: this.$t('enums.statement-type.INDEX'), value: 'INDEX'},
        {text: this.$t('enums.statement-type.SESSION'), value: 'SESSION'},
      ]
    },

    location: {
      get() {
        if(this.form?.location) {
          return {...this.form?.location, organization: this.form?.organization, organizationId: undefined}
        }
        return null
      },

      set(value) {
        const location = this.cloneObj(value)
        const { organization } = location
        delete location.organization
        this.form.location = location
        this.form.organization = organization
      }
    }
  },

  methods: {
    cancel() {

    },

    loadTypes() {
      MeterDeviceRepository.metertypes().then(data => {
        this.types = data.map(d => ({text: this.$t('enums.meter-device.' + d), value: d}))
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
      })
    },


    save() {
      if (!this.location) {
        this.notifyError('location.select-location')
        return
      }
      if (this.$refs.form.validate()) {
       this.showLoading(true)

        const form = this.cloneObj(this.form)

        form.initialMeterValue = form.initialMeterValue * 1000
        form.lastMeterValue = form.lastMeterValue * 1000
        form.maxMeterValue = form.maxMeterValue * 1000

        const locationId = form.location?.id
        const customerId = form.customer?.id
        const organizationId = form.organization?.id

        delete form.location
        delete form.customer
        delete form.organization

        const data = {
          ...form,
          locationId,
          customerId,
          organizationId,
        }
        MeterDeviceRepository.update(this.id, data).then(response => {
          this.showLoading(false)
          this.$dialog.notify.success(this.$t('update-success'))
          this.$parent.loadData().then(() => {
            this.cleanUnsavedChanges()
            this.$router.push({name: 'meter-device.detail'})
          })
        }).catch(err => {
          this.showLoading(false)
          this.notifyError(err)
        })
      } else {
        this.notifyError('form-has-errors')
      }
    },


    // This can also prevent copy + paste invalid character
    filterInput(e){
      console.log(e)
      // e.target.value = e.target.value.replace(/[^0-9]+/g, '');
    }

  }
}
</script>

<style lang="scss">
.meter-device-edit {

  .meter-device-location {
    width: 80%;
    float: right;
  }

  .vue-tel-input-vuetify {
    //transform: translateY(-8px);
    .v-select {
      min-width: unset;
      background-color: white;
      border-radius: 4px;
      border: 1px solid #758093 !important;
      box-shadow: 0 10px 30px #00000029 !important;
      padding-left: 5px;
      padding-right: 15px;
    }

    & > .v-text-field {
      transform: translateY(-11px);
    }
}


}
</style>
