<template>
  <div class="organization-overview  px-5">

    <div class="grid pa-0">

      <headline>
        {{ $tc('meter-device.meter-device') }}
      </headline>
      <v-row>
        <v-col class="label" sm="3">
          {{ $t('meter-device.type') }} :
        </v-col>
        <v-col sm="9">
          {{ $t(`enums.meter-device.${meterDevice.type}`) }}
          <v-btn class="ml-5" small :to="{name: 'meter-device.edit'}">
            <v-icon left>mdi-cog</v-icon>
            {{ $t('edit')}}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label"  sm="3">
          {{ $t('meter-device.statement-mode') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.statementMode }}
        </v-col>

        <v-col class="label"  sm="3">
          {{ $t('meter-device.statement-type') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.statementType }}
        </v-col>
      </v-row>


      <headline class="mt-10">
        {{ $tc("general-information") }}
      </headline>

      <v-row>
        <v-col class="label" sm="3">
          {{ $t('name') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.name ? meterDevice.name : '-' }}
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('external-reference') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.externalId}}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" sm="3">
          {{ $t('meter-device.brand') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.brand }}
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('meter-device.model') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.model }}
        </v-col>
      </v-row>

      <headline class="mt-10">
        {{ $tc("customer.customer") }}
      </headline>
      <v-row >
        <v-col class="label"  sm="3">
          {{ $tc('customer.customer') }} :
        </v-col>
        <v-col  md="9">
          <router-link :to="{name: 'customer.detail', params: {id: meterDevice.customer.id }}" v-if="meterDevice.customer">
           {{ meterDevice.customer.name || (meterDevice.customer.firstname ? meterDevice.customer.firstname : '') + (meterDevice.customer.firstname && meterDevice.customer.lastname ? ' ' + meterDevice.customer.lastname : '') }}

          </router-link>
          <template v-else>-</template>
        </v-col>
      </v-row>

      <headline class="mt-10">
        {{ $tc("location.location") }}
      </headline>
      <v-row>
        <v-col class="label"  sm="3">
          {{ $t('name') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.location?.name }}
        </v-col>
        <v-col class="label"  sm="3">
          {{ $t('organization.organization') }} :
        </v-col>
        <v-col sm="3" class="text-center">
          <router-link :to="{name: 'organizations.detail', params: {id: meterDevice.organization.id }}" v-if="meterDevice.organization">
            {{ meterDevice.organization.name}}
          </router-link>
          <template v-else>-</template>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label"  sm="3">
          {{ $t('street') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.location?.address?.street }}
        </v-col>
        <v-col class="label"  sm="3">
          {{ $t('state') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.location?.address?.state }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="label"  sm="3">
          {{ $t('city') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.location?.address?.city }}
        </v-col>
        <v-col class="label"  sm="3">
          {{ $t('zipcode') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.location?.address?.zipcode }}
        </v-col>
      </v-row>

      <headline class="mt-10">
        {{ $t("settings") }}
      </headline>
      <v-row>
        <v-col class="label"  sm="3">
          {{ $t('meter-device.initial-meter-value') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.initialMeterValue ? (meterDevice.initialMeterValue / 1000) + " " + $t('usages.kwh') : '-' }}
        </v-col>

        <v-col class="label"  sm="3">
          {{ $t('meter-device.max-meter') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.maxMeterValue ? (meterDevice.maxMeterValue / 1000) + " " + $t('usages.kwh') : '-' }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="label"  sm="3">
          {{ $t('meter-device.last-meter-value') }} :
        </v-col>
        <v-col sm="3">
          {{ meterDevice.lastMeterValue ? meterDevice.lastMeterValue / 1000 + " " + $t('usages.kwh') : '-' }}
        </v-col>
        <v-col class="label"  sm="3">
          {{ $t('meter-device.last-meter-date') }} :
        </v-col>
        <v-col sm="3">
           <span v-if="meterDevice.lastMeterDate">
            {{ meterDevice.lastMeterDate |  formatDate($t('format_date'))}}
          </span>
          <span v-else>-</span>
        </v-col>
      </v-row>


      <v-row>
        <v-col class="label"  sm="3">
          {{ $t('electricity-tariff.value-per-kwh') }} :
        </v-col>
        <v-col sm="3">
          <meter-device-energy-price-module
              :meter-device-id="meterDevice.id"
              readonly
          />
        </v-col>
      </v-row>



    </div>

  </div>
</template>

<script>

import MeterDeviceEnergyPriceModule from "@pages/MeterDevice/Detail/modals/MeterDeviceEnergyPriceModule.vue";

export default {
  components: {MeterDeviceEnergyPriceModule},
  computed: {
    meterDevice() {
      return this.$parent.meterDevice ?? null
    },


  },

  methods: {


  }
}
</script>

<style lang="scss">


.organization-overview {

}

</style>
