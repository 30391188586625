<template>
  <div class="pa-10">

    <title-page icon="ico-price-list">
      {{ $t('price-index.search') }}
    </title-page>

    <v-row v-if="currentUser.permissions.includes('OPERATOR_WRITE')">
      <v-col class="text-right">
        <v-btn :to="{name: 'price-index.add'}">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('price-index.add')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <search-field
            :placeholder="$t('price-index.search-placeholder')"
            :search.sync="search"
            clearable
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
          class="cp-list"
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :server-items-length="total"
        >
          <template #item.name="{item, value}">
            <router-link :to="{name: 'price-tariff', params: {priceIndexId: item.id}}">
              {{ value }}
            </router-link>
          </template>
          <template #item.options="{item}">
            <v-btn icon :to="{name: 'price-index.edit', params: {id: item.id}}">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="deletePriceIndex(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>






  </div>
</template>

<script>


import CustomerRepository from "@repository/CustomerRepository";
import ExportDefault from "@blocks/ExportDefault.vue";
import PriceIndexRepository from "@repository/PriceIndexRepository";
import PriceTariffRepository from "@repository/PriceTariffRepository";
import MemberGroupAssociationRepository from "@repository/MemberGroupAssociationRepository";

export default {
  components: {

  },
  data() {
    return {
      loading: false,
      search: null,
      items: [],
      total: 0,
      options: null,

    }
  },

  mounted() {
    if(!this.currentUser.permissions.includes('OPERATOR_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
  },

  watch: {
    search(val) {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.searchData()
      }
    },

    options(val) {
      this.searchData()
    }
  },

  computed: {
    headers() {
      return [
        {text: this.$t('name'), value: 'name'},
        {text: '', value: 'options', width: 200},
      ]
    },

  },

  methods: {
    searchData() {

      let options = this.options



      this.loading = true
      let query = this.search;
      let offset = options.itemsPerPage * options.page - options.itemsPerPage;
      let limit = options.itemsPerPage;
      let orderBy = options.sortBy.length > 0 ? options.sortBy[0] : 'name'
      let orderDirection = options.sortDesc.length > 0 && options.sortDesc[0] ? 'desc' : 'asc'



      this.loading = true
      PriceIndexRepository.search(query,
          offset,
          limit,
          orderBy,
          orderDirection,
      ).then(result => {
        this.total = result.total
        this.items = result.items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.loading = false
      })
    },
    deletePriceIndex(priceIndexId)  {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          PriceIndexRepository.delete(priceIndexId).then(response => {
            this.$dialog.notify.success(this.$t('update-success'))
            this.showLoading(false)
            this.searchData();
          }).catch(e => {
            this.showLoading(false)
            this.$dialog.notify.error(e.response.data.message)
          })
        }
      })
    },


  }
}
</script>

<style lang="scss">



</style>