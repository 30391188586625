import Vue from "vue";

class ExportDefaultRepository {
  exportXls(data,headers) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: `/api/export/test`,
        method: 'post',
        data: {
          headers,
          data,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}


export default new ExportDefaultRepository()