<template>
  <div class="meter-device-usage" v-if="meterDevice">
    <report-usages-list :meterDeviceId="meterDevice.id" />
  </div>
</template>

<script>
import ReportUsagesList from "@/components/elements/ReportUsagesList.vue";

export default {
  components: {ReportUsagesList},

  computed:  {
    meterDevice() {
      return this.$parent.meterDevice
    },
  },
}
</script>

<style lang="scss">

</style>
