<template>
  <div class="rfid-info pa-10">
    <headline>
      {{ $t('rfid.rfid') }}
    </headline>

    <div class="grid">
      <v-row>
        <v-col class="label" sm="3">
          {{ $t('rfid.rfid-card-no') }} :
        </v-col>
        <v-col sm="3">
          {{ token.uid}}
          <v-btn class="ml-5" small :to="{name: 'tokens.edit', params: {id: token.id}}">
            <v-icon left>mdi-cog</v-icon>
            {{ $t('edit')}}
          </v-btn>
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('rfid.rfid-card-name') }} :
        </v-col>
        <v-col sm="3">
          {{ token.name}}
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('rfid.rfid-card-physical-reference') }} :
        </v-col>
        <v-col sm="3">
          {{ token.physicalReference}}
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('rfid.rfid-card-enabled') }} :
        </v-col>
        <v-col sm="3">
          <div class="switch">
            <v-switch
                readonly
                color="#00897E"
                inset
                hide-details
                v-model="token.enabled"
            />
          </div>
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('rfid.rfid-corporate') }} :
        </v-col>
        <v-col sm="3">
          <div class="switch">
            <v-switch
                readonly
                color="#00897E"
                inset
                hide-details
                v-model="token.corporate"
            />
          </div>
        </v-col>
        <v-col class="label" sm="3">
          {{ $tc('customer.customer', 1) }} :
        </v-col>
        <v-col sm="3">
          {{ token?.customer?.lastname}} {{ token?.customer?.firstname}}
        </v-col>
        <v-col class="label" sm="3">
          {{ $tc('organization.organization', 1) }} :
        </v-col>
        <v-col sm="3">
          {{ token?.organization?.name}}
        </v-col>
      </v-row>

    </div>
  </div>
</template>

<script>

export default {
  computed: {
    token() {
      return this.$parent.token ?? null
    },


  },

  methods: {


  }
}
</script>

<style lang="scss">
.charging-point-type-info {
  .evses {
    .row {
      margin: -4px;
      &  + .row {
        margin-top: 4px;
      }
      & > .col, & > [class*=col-] {
        padding: 4px;
        &:not(.label) {
          justify-content: center;
        }
      }
    }
    .v-expansion-panel {
      background: white;
    }
    .evse {
      //border: 1px dotted #ccc;
      border-radius: 10px;
      padding: 0 20px;
      .sub-headline {
        margin: 20px 0;
        font-weight: 500;
      }
      .physical-reference {
        border: 2px solid var(--v-primary-base);
        color: var(--v-primary-base);
        border-radius: 15px;
        padding: 0px 10px;
        font-size: 16px;
        line-height: 22px;
        height: 26px;
        font-weight: bold;
        box-shadow: 0 0 15px #e53c225e;

      }
      .connectors {
        .connector {
          margin: 0 30px;
          border-radius: 10px;
          .grid {
            .headline-title {
              .col {
                color: var(--v-primary-base);
                font-weight: 600;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

</style>
