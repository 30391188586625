import Vue from 'vue'

class PriceTariffRepository {
    search(priceIndexId, offset = 0, limit = 20, orderBy = 'period', orderDirection = 'desc') {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/pricing/price-tariff/search',
                method: 'post',
                data: {
                    priceIndexId,
                    offset,
                    limit,
                    orderBy,
                    orderDirection,
                }
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    create(priceIndexId, data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/pricing/price-tariff',
                method: 'post',
                data: {
                    priceIndexId,
                    ...data,
                },
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/pricing/price-tariff/' + id,
                method: 'put',
                data,
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/pricing/price-tariff/' + id,
                method: 'delete',
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    get(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/pricing/price-tariff/' + id,
                method: 'get',
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }
}

export default new PriceTariffRepository()