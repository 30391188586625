<template>
  <div class="session-authorization pa-10">
    <title-page icon="ico-carplug">
      {{ $t('session-authorization.logs') }}
    </title-page>
    <session-authorization-list :token="token"/>

  </div>
</template>

<script>


import SessionAuthorizationList from "@/components/elements/SessionAuthorizationList.vue";


export default {
  components: {
    SessionAuthorizationList
  },

  computed: {
    token() {
      return this.$parent.token ?? null
    },
  },
}
</script>

<style lang="scss">


</style>
