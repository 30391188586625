import Vue from "vue";

class TokenRepository {

    controller = null

    search(data) {

        if(this.controller) {
            this.controller.abort()
        }
        this.controller = new AbortController()

        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: `/api/tokens/search`,
                method: 'post',
                data,
                signal: this.controller.signal,
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    get(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/tokens/get/' + id,
                method: 'get',
            })
            .then(response => {
                    resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    create(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/tokens',
                method: 'post',
                data,
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    createMultiple(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/tokens-multiple',
                method: 'post',
                data,
            })
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/tokens/' + id,
                method: 'delete',
            })
            .then(response => {
                    resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    update(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/tokens/' + data.id,
                method: 'post',
                data,
            })
            .then(response => {
                    resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    attachToOrganizationPaymentMethod(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/tokens/' + id + '/attach-to-organization-payment-method',
            })
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    actionForMultipleRfid(action, tokenIds) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/tokens/multiple',
                method: 'post',
                data: {
                    action,
                    tokenIds,
                }
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    updateTokensPaymentMethod(selectedRfids, selectedPaymentMethod, customerId) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/update-tokens-payment-method',
                method: 'post',
                data: {
                    'tokens': selectedRfids,
                    'paymentMethodId': selectedPaymentMethod,
                    'customerId': customerId,
                }
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default new TokenRepository()
