<template>
  <div>
    <v-menu left offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-3"
            small
        >
          <v-icon>mdi-table-arrow-right</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="exportFile('all')">
          <v-list-item-title>{{ $t('export') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="exportFile('display')">
          <v-list-item-title>Display</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

import ExportDefaultRepository from "@repository/ExportDefaultRepository";

export default {
  props: {
    items: Array, //JSON for xcel rows
    headers: Array,
    value: {type: String, default: null},
  },

  methods: {
    exportFile(type) {
      let headers = '';
      if (type === 'display') {
        //column displayed in vue user front
        headers =this.headers.map(obj => obj.text);
      } else (
          //headers brut (DB name field)
          headers = Object.keys(this.items[0])
      )
      ExportDefaultRepository.exportXls(this.items, headers).then(results => {

      }).catch(err => {
        this.$dialog.notify.error('err.message')
      })
    },
  }
}
</script>

<style lang="scss">
.back-btn-circle {
  border-radius: 50% !important;
  min-width: 0px !important;
  width: 70px !important;
  height: 70px !important;
}

</style>