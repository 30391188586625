<template>
    <report-usages-list :tokenUid="token?.uid"/>
</template>

<script>
import ReportUsagesList from "@/components/elements/ReportUsagesList.vue";

export default {
  components: {ReportUsagesList},

  computed: {
    token() {
      return this.$parent.token ?? null
    },


  },
}
</script>

<style lang="scss">

</style>
